import _ from "lodash-es";
import { Events, kWin, MBUI, isDarkModeSupported } from "./constants";
import { EventEmitterInstance } from "./EventEmitter";
export const onWindowOptimizedResize = (callback, wait = 1, options) =>
  EventEmitterInstance.on(
    Events.kOptimizedResize,
    _.debounce((event) => callback({ width: kWin.innerWidth, height: kWin.innerHeight }, event), wait, options)
  );

export const onWindowOptimizedScroll = (callback, wait = 1000, options) =>
  EventEmitterInstance.on(
    Events.kOptimizedScroll,
    _.throttle((event) => () => callback(event), wait, options)
  );

kWin.addEventListener(Events.kResize, (event) => EventEmitterInstance.emit(Events.kOptimizedResize, event), {
  passive: true,
});
kWin.addEventListener(Events.kScroll, (event) => EventEmitterInstance.emit(Events.kOptimizedScroll, event), {
  passive: true,
});

export const onColorSchemeChange = (callback) =>
  EventEmitterInstance.on(Events.kDarkModeOn, (event) => callback({ isDarkMode: event.matches }, event));

if ("addEventListener" in isDarkModeSupported()) {
  isDarkModeSupported().addEventListener("change", (event) => EventEmitterInstance.emit(Events.kDarkModeOn, event));
} else if ("addListener" in isDarkModeSupported()) {
  isDarkModeSupported().addListener((event) => EventEmitterInstance.emit(Events.kDarkModeOn, event));
}

Object.assign(MBUI.events, {
  // addEventListenerDarkMode: 'addEventListener' in isDarkModeSupported(),
  // addListenerDarkMode: 'addListener' in isDarkModeSupported(),
  onWindowOptimizedResize,
  onWindowOptimizedScroll,
  onColorSchemeChange,
});
