import _ from "lodash-es";
import { promiseDefer } from "./constants";
import "./bindGlobalEventListeners";
import objectFitImages from "object-fit-images";
import { TinyColor } from "@ctrl/tinycolor";

import(/* webpackPreload: 1 */ /* webpackChunkName: "lazyLibs" */ "./lazyLibs");
import(/* webpackPreload: 0 */ /* webpackChunkName: "lazyCodeMirror" */ "./lazyCodeMirror");

Object.assign(window, {
  objectFitImages,
  TinyColor,
  _: {
    assign: _.assign,
    assignIn: _.assignIn,
    compact: _.compact,
    debounce: _.debounce,
    debounceFn: _.debounce((fn) => fn(), 300),
    debounceFastFn: _.debounce((fn) => fn(), 150),
    delay: _.delay,
    find: _.find,
    forEach: _.forEach,
    get: _.get,
    has: _.has,
    hasIn: _.hasIn,
    isEmpty: _.isEmpty,
    isEqual: _.isEqual,
    isNil: _.isNil,
    isNull: _.isNull,
    isString: _.isString,
    isUndefined: _.isUndefined,
    kebabCase: _.memoize(_.kebabCase),
    camelCase: _.memoize(_.camelCase),
    memoize: _.memoize,
    merge: _.merge,
    merge: _.merge,
    noop: _.noop,
    promiseDefer,
    result: _.result,
    round: _.memoize(_.round),
    split: _.split,
    throttle: _.throttle,
    throttleFastestFn: _.throttle((fn) => fn()),
    throttleFastFn: _.throttle((fn) => fn(), 150),
    throttleFn: _.throttle((fn) => fn(), 300),
    throttleSlowFn: _.throttle((fn) => fn(), 500),
    toArray: _.toArray,
    toNumber: _.toNumber,
    uniqueId: _.uniqueId,
    toSafeInteger: _.toSafeInteger,
    parseInt: _.parseInt,
    values: _.values,
  },
});
